.contact {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
}

.contact-card {
	background: #333333;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	max-width: 600px;
	width: 100%;
	overflow: hidden;
}

.contact-banner {
	position: relative;
	width: 100%;
	height: 150px; /* Height of the banner */
}

.contact-banner img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.contact-profile {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
}

.profile-picture {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	border: 5px solid #2c2f33;
	margin-bottom: 1rem;
	object-fit: cover;
}

.contact-profile h3 {
	margin: 0;
	color: white;
	font-size: 2rem;
}

.contact-profile p {
	color: white;
	font-size: 1.2rem;
	margin: 0.5rem 0;
}
