.not-found {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 3rem;
  }

.not-found h1 {
	font-size: 6rem;
	margin-bottom: 1rem;
}

.not-found p {
	font-size: 1.5rem;
	margin-bottom: 2rem;
}

.home-link {
	font-size: 1.2rem;
	color: #007bff;
	text-decoration: none;
	border: 2px solid #007bff;
	padding: 0.5rem 1rem;
	border-radius: 5px;
	transition: background-color 0.3s ease, color 0.3s ease;
}

.home-link:hover {
	background-color: #007bff;
	color: white;
}
  