body {
	font-family: Arial, sans-serif;
	margin: 0;
	color: white;
	background: linear-gradient(135deg, #434343, #000000);
	background-attachment: fixed; /* Ensures the gradient stays in place */
	min-height: 100vh; /* Makes sure the body is at least as tall as the viewport */
}

header {
	background-color: #333333;
	color: white;
	padding: 0.5rem;
	text-align: center;
	opacity: 80%;
}

header h1 {
	margin: 0;
	font-size: 2rem;
}

nav ul {
	list-style: none;
	padding: 0;
	display: flex;
	justify-content: center;
	margin: 1rem 0 0;
}

nav ul li {
	margin: 0 1rem;
}

nav ul li a {
	color: white;
	text-decoration: none;
	font-weight: bold;
}

nav ul li a:hover {
	text-decoration: underline;
}

.content-wrapper {
	display: flex;
	justify-content: space-between;
	padding: 2rem;
}

main {
	flex: 3;
}

/* aside.company-info {
	flex: 1;
	background-color: #fff;
	padding: 1rem;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	position: sticky;
	top: 1rem;
	height: fit-content;
}

aside.company-info h2 {
	margin-top: 0;
} */

footer {
	/* background-color: #333333; */
	color: white;
	padding: 0.5rem;
	text-align: left;
	position: fixed;
	width: 100%;
	bottom: 0;
	opacity: 80%;
}

h2 {
	color: #007bff;
}

p {
	margin: 0.5rem 0;
}

/* .contact-form {
	padding: 20px;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	max-width: 750px;
	margin: 0 auto;
}

.contact-form label {
	display: block;
	margin-bottom: 10px;
}

.contact-form input,
.contact-form textarea {
	width: calc(100% - 20px);
	padding: 10px;
	margin-top: 5px;
	margin-bottom: 15px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.contact-form button {
	background-color: #007bff;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
}

.contact-form button:hover {
	background-color: #0056b3;
} */
