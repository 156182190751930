.home {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 3rem;
}

.intro-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 40%; /* Reduce height to move icons section higher */
	text-align: center;
}

.intro-section h1 {
	font-size: 4rem; /* Increase size */
	margin-bottom: 2rem;
}

.intro-section p {
	font-size: 1.8rem; /* Increase size */
	margin-bottom: 2rem;
}

.skills-section {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	padding: 2rem 0;
}

.skills-grid {
	display: flex;
	justify-content: center;
	flex-wrap: wrap; /* Allow wrapping into multiple rows */
	width: 90%; /* Adjust width */
	gap: 2.5rem; /* Space between icons */
	padding: 1rem;
}

.icon-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 150px; /* Increase size of the icon container */
	transition: transform 0.3s ease; /* Smooth transition for the hover effect */
}

.icon-text svg {
	width: 2rem; /* Set icon size */
	height: 2rem; /* Set icon size */
	margin-bottom: 1rem;
	transition: transform 0.3s ease; /* Smooth transition for hover */
}

.icon-text:hover svg {
	transform: scale(1.5); /* Increase the size of the icon when hovered */
}

.icon-text p {
	font-size: 1.35rem; /* Increase text size */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
	.intro-section h1 {
		font-size: 2.5rem;
	}

	.intro-section p {
		font-size: 1.2rem;
	}

	/* .skills-grid {
		justify-content: flex-start;
	} */

	.icon-text {
		width: 50px; /* Adjust icon size for mobile */
	}

	.icon-text svg {
		width: 1.5rem; /* Adjust icon size on mobile */
		height: 1.5rem;
	}

	.icon-text p {
		font-size: 1rem;
	}
}
