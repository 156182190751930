body {
	font-family: Arial, sans-serif;
	margin: 0;
	padding: 0;
	background-color: #f8f9fa;
	color: #333;
}

a {
	color: inherit;
}
