.projects {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* padding: 2rem; */
}

.project-list {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 100%;
	max-width: 1200px; /* Keep the list centered */
	margin: auto; /* Center the list */
}

.project-card {
	background: #333333;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 1.25rem; /* Increased padding for more space */
	margin: auto;
	opacity: 80%;
	max-width: 790px; /* Set a max-width for the card */
	position: relative;
}

.projects h2 {
	font-size: 2.5rem;
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	color: white; /* Dark color for header */
}

.project-card .project-logo {
	position: absolute;
	top: 1rem;
	right: 1rem;
	width: 125px;
	height: auto;
	border-radius: 50%;
}

.project-card h3, 
.project-card p {
	margin-right: 120px; /* Add margin-right to avoid overlap with logo */
}

.project-card h3 {
	font-size: 2rem;
	margin: 0rem;
	color: white;
}

.project-card .project-description {
	font-size: 1.25rem;
	margin-bottom: 2rem; /* Increased space below description */
}

.project-card p {
	font-size: 1.25rem;
	margin-bottom: 0.5rem;
}

.project-card a {
	color: rgb(129, 123, 123); /* Link color */
	text-decoration: none;
}

.project-card a:hover {
	text-decoration: underline;
}

@media (max-width: 768px) {
	.project-card {
		padding: 1rem;
		max-width: 100%; /* Ensure card fits on smaller screens */
	}

	.project-card h3 {
		font-size: 1.5rem;
	}

	.project-card p {
		font-size: 0.9rem;
	}
}